<template>
  <v-row class="staff">
    <v-col cols="12" class="pa-0">
      <div class="title">
        <div class="text-sm-h5 text-h6 font-weight-bold">
          <router-link to="/staff" class="white--text">
            <v-icon class="white--text text-sm-h4 text-h5 mb-1">mdi-chevron-left</v-icon>Staff profile
          </router-link>
        </div>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <v-card v-else elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5">
        <div class="d-flex flex-wrap justify-space-between mb-sm-0 mb-3">
          <div class="text-uppercase text-h5 font-weight-bold">{{ data.first_name }} {{ data.last_name }}</div>
          <div class="d-flex">
            <div class="f18 role">{{ data.role.title }}</div>
            <div class="status ml-5" :class="data.is_active ? 'success' : 'error'">
              {{ data.is_active ? 'Active' : 'Deactived' }}
            </div>
          </div>
        </div>
        <div class="px-sm-10 py-sm-5">
          <v-row>
            <v-col cols="12" md="4">
              <div v-if="data.photo === null && !image">
                <v-img @click="changeAvatar()" width="256" src="@/assets/img/ava.svg" class="link mx-md-0 mx-auto"></v-img>
              </div>
              <div v-else class="mx-auto">
                <v-img @click="changeAvatar()" width="256" height="216" cover :src="image || data.photo" class="link"></v-img>
              </div>
              <div class="mt-4 d-flex flex-md-nowrap justify-md-space-between justify-center">
                <v-btn
                  :disabled="!data.photo || !data.is_active"
                  class="mr-lg-0 mr-8 rounded"
                  width="48"
                  height="48"
                  icon
                  tile
                  elevation="0"
                  @click="deletePhoto"
                >
                  <v-icon color="error">mdi-delete</v-icon>
                </v-btn>
                <v-btn
                  elevation="0"
                  class="mr-md-8 mr-0"
                  color="secondary"
                  max-width="185"
                  width="100%"
                  height="48"
                  @click="changeAvatar"
                  :disabled="!data.is_active"
                >
                  Upload
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="data.first_name"
                :error-messages="firstnameErrors"
                label="First Name"
                filled
                outlined
                dense
                required
                color="input"
                maxlength="50"
                :disabled="!data.is_active"
              ></v-text-field>
              <v-text-field
                v-model="data.last_name"
                :error-messages="lastnameErrors"
                label="Last Name"
                filled
                outlined
                dense
                required
                color="input"
                maxlength="50"
                :disabled="!data.is_active"
              ></v-text-field>
              <v-text-field
                v-model="data.email"
                :error-messages="emailErrors"
                label="Email Address"
                filled
                outlined
                dense
                required
                color="input"
                maxlength="50"
                :disabled="!data.is_active"
              ></v-text-field>
              <div class="d-flex mt-n6" v-if="!emailErrors.length">
                <div v-if="data.is_email_confirmed" class="success--text mb-3">
                  Confirmed {{ new Date(data.email_confirmed_at).toLocaleString() }}
                </div>
                <div v-else>
                  <div class="error--text">Email is not confirmed yet</div>
                  <div class="input--text link my-3" @click="sendEmail">
                    <v-icon class="mb-1 me-2" color="input">mdi-reload mdi-18px</v-icon>Resend link
                  </div>
                </div>
              </div>
              <v-select
                :items="roleList"
                v-model="data.role"
                :error-messages="roleErrors"
                label="Role"
                item-text="title"
                item-value="name"
                return-object
                filled
                outlined
                dense
                required
                color="input"
                :disabled="!data.is_active"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-divider class="my-10"></v-divider>
          <v-btn v-if="!data.is_active" elevation="0" outlined width="180" height="48" color="success" @click="activeModal = !activeModal">
            Activate
          </v-btn>
          <div v-else class="d-flex flex-wrap justify-space-between">
            <div>
              <v-btn elevation="0" outlined width="180" height="48" color="error" @click="deactiveModal = !deactiveModal">Deactivate</v-btn>
              <v-btn
                :disabled="!data.is_email_confirmed"
                elevation="0"
                class="ml-5"
                width="180"
                height="48"
                color="secondary"
                @click="resetModal = !resetModal"
              >
                Reset password
              </v-btn>
            </div>
            <v-btn elevation="0" width="180" height="48" color="primary" @click="upade">Save</v-btn>
          </div>
        </div>
      </v-card>
      <v-dialog v-model="deactiveModal" max-width="536">
        <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4 text-center">
          <div class="text-h5 font-weight-bold">Deactivate acount</div>
          <div class="mt-3 opasity--text">Do you realy want to deactivate {{ data.first_name }} {{ data.last_name }} account?</div>
          <div class="mt-7">
            <v-btn width="120" height="48" class="mr-4 white--text" color="gray" @click="deactiveModal = !deactiveModal">no</v-btn>
            <v-btn width="120" height="48" color="secondary" @click="deactive">yes</v-btn>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-model="activeModal" max-width="536">
        <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4 text-center">
          <div class="text-h5 font-weight-bold">Activate acount</div>
          <div class="mt-3 opasity--text">Do you realy want to activate {{ data.first_name }} {{ data.last_name }} account?</div>
          <div class="mt-7">
            <v-btn width="120" height="48" class="mr-4 white--text" color="gray" @click="activeModal = !activeModal">no</v-btn>
            <v-btn width="120" height="48" color="secondary" @click="active">yes</v-btn>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-model="resetModal" max-width="536">
        <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4 text-center">
          <div class="text-h5 font-weight-bold">Reset Password</div>
          <div class="mt-3 opasity--text">
            Do you realy want to reset {{ data.first_name }} {{ data.last_name }} password? {{ data.first_name }} {{ data.last_name }} will
            reseive the email with a new password.
          </div>
          <div class="mt-7">
            <v-btn width="120" height="48" class="mr-4 white--text" color="gray" @click="resetModal = !resetModal">no</v-btn>
            <v-btn width="120" height="48" color="secondary" @click="reset">yes</v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  validations: {
    data: {
      first_name: { required },
      last_name: { required },
      email: { required, email },
      role: { required },
    },
  },
  data() {
    return {
      load: true,
      resetModal: false,
      activeModal: false,
      deactiveModal: false,
      file: '',
      image: '',
      error: null,
    };
  },
  mounted() {
    this.$store.dispatch('getStaff').finally(() => {
      this.load = false;
    });
    this.$store.dispatch('getStaffRoles');
  },
  computed: {
    data() {
      return this.$store.getters.staff;
    },
    roleList() {
      return this.$store.getters.staffRoleList;
    },
    firstnameErrors() {
      const errors = [];
      if (!this.$v.data.first_name.$dirty) {
        return errors;
      }
      !this.$v.data.first_name.required && errors.push('Please enter your first name');
      this.error == 'first_name__invalid' && errors.push('Provided first name is not valid');
      return errors;
    },
    lastnameErrors() {
      const errors = [];
      if (!this.$v.data.last_name.$dirty) {
        return errors;
      }
      !this.$v.data.last_name.required && errors.push('Please enter your last name');
      this.error == 'last_name__invalid' && errors.push('Provided last name is not valid');
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.data.email.$dirty) {
        return errors;
      }
      !this.$v.data.email.email && errors.push('Provided email address is not valid');
      !this.$v.data.email.required && errors.push('Please enter your email address');
      this.error == 'email__invalid' && errors.push('Provided email address is not valid');
      this.error == 'email__exists' && errors.push('Provided email address is already registered. Please use another one');
      return errors;
    },
    roleErrors() {
      const errors = [];
      if (!this.$v.data.role.$dirty) {
        return errors;
      }
      !this.$v.data.role.required && errors.push('Please enter your role address');
      this.error == 'role__required' && errors.push('Please enter your role address');
      this.error == 'role__invalid' && errors.push('Provided role address is not valid');
      return errors;
    },
  },
  methods: {
    notifi(btn) {
      if (btn == 'updatePhoto') {
        this.$notify({ message: 'Your staff picture was successfully updated', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'update') {
        this.$notify({ message: 'Your staff has been updated', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'confirm') {
        this.$notify({ message: 'Email confirmation was sent successfully', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (this.error == 'confirmation__too_often') {
        this.$notify({
          message: 'Sorry, but email confirmation requests limit exceeded. Try again later',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (this.error == 'email__already_confirmed') {
        this.$notify({ message: 'Email is already confirmed', type: 'warning', horizontalAlign: 'center', verticalAlign: 'top' });
      }
    },
    async upade() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.error = null;
        const data = {
          first_name: this.data.first_name,
          last_name: this.data.last_name,
          email: this.data.email,
          role: this.data.role.name,
        };
        await this.$store
          .dispatch('updateStaff', data)
          .then(() => {
            this.notifi('update');
          })
          .catch((e) => {
            this.error = e.response.data.error[0];
            this.notifi();
          });
      }
    },
    changeAvatar() {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          self.file = this.files[0];
          var reader = new FileReader();
          reader.onload = (e) => {
            self.image = e.target.result;
            self.updatePhote();
          };
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    async updatePhote() {
      await this.$store.dispatch('setPhotoStaff', this.file).then(() => {
        this.file = '';
        this.notifi('updatePhoto');
      });
    },
    async deletePhoto() {
      await this.$store.dispatch('deletePhotoStaff').then(() => {
        this.image = '';
        this.$store.dispatch('getStaff');
      });
    },
    async sendEmail() {
      await this.$store
        .dispatch('sendEmailStaff')
        .then(() => {
          this.notifi('confirm');
        })
        .catch((e) => {
          this.error = e.response.data.error[0];
          this.notifi();
        });
    },
    async reset() {
      await this.$store.dispatch('resetPassStaff');
      this.resetModal = !this.resetModal;
    },
    async deactive() {
      await this.$store.dispatch('deactivateStaff');
      this.deactiveModal = !this.deactiveModal;
    },
    async active() {
      await this.$store.dispatch('activateStaff');
      this.activeModal = !this.activeModal;
    },
  },
  destroyed() {
    this.$store.dispatch('setStaff', { role: {} });
  },
};
</script>

<style lang="scss" scoped>
.staff {
  .role {
    text-align: center;
    text-transform: capitalize;
    line-height: 38px;
    padding: 0 24px;
    height: 38px;
    border-radius: 24px;
    color: #0f5dbb;
    background: #b2defa;
  }
  .status {
    text-align: center;
    border-radius: 24px;
    font-weight: 500;
    line-height: 38px;
    padding: 0 16px;
    color: white;
  }
}
</style>
